import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Address } from "viem";
import Highcharts, {
  Chart as HighchartsChart,
  PointClickCallbackFunction,
  PointClickEventObject,
  PointMouseOutCallbackFunction,
  PointMouseOverCallbackFunction,
  PointOptionsObject,
} from "highcharts";
import { useAccount } from "wagmi";
import { findIndex, get, keys } from "lodash";
import { getUnixTime } from "date-fns";
import { Box, BoxProps, Flex, useColorMode, useDisclosure } from "@chakra-ui/react";
import { colors, palette, semanticTokens } from "@looksrare/chakra-theme";
import {
  defaultConfettiOptions,
  defaultHighchartsOptions,
  HighchartsTooltip,
  HighchartsTooltipFormatterFunction,
  useThrowConfettiFromElement,
} from "@looksrare/uikit";
import { formatAddress, isAddressEqual, usePreviousValue } from "@looksrare/utils";
import { useCountdownPercentage } from "../../../utils/useCountdownPercentage";
import { CurrentRoundProps, Deposit, Round, RoundStatus } from "../../../types";
import { useYoloStore } from "../../../utils/yoloStore";
import { useCurrentParticipant, getNetworkFromYoloContractName } from "../../../utils";
import { useCurrentRoundStore } from "../../../currentRoundStore";
import { PlayerTile } from "../PlayerTile";
import { PlayerProfileModal } from "../../modals/PlayerProfileModal";
import { useYoloConfig } from "../../../config";
import { CenterWinner } from "./CenterWinner";
import { CenterActive } from "./CenterActive";
import { useTimerColor } from "./useTimerColor";
import { useRouletteSpin } from "./useRouletteSpin";
import { CenterFailed } from "./CenterFailed";

// PointOptionsObject has to be manually extended
// @see https://api.highcharts.com/class-reference/Highcharts.PointOptionsObject
type PointType = PointOptionsObject &
  Partial<{
    userAddress: Address;
    deposits: Deposit[];
  }>;

interface Props extends CurrentRoundProps, BoxProps {
  round: Round;
  depositsPerAddress: Record<string, Deposit[]>;
  userColors: Record<string, string>;
  setIsWinnerHighlighted: Dispatch<SetStateAction<boolean>>;
  startClaim: () => void;
  startWithdraw: () => void;
}

export const Roulette = ({
  round,
  finishRound,
  timeBeforeNextRound,
  userColors,
  setIsWinnerHighlighted,
  isHistoricRound,
  depositsPerAddress,
  startClaim,
  startWithdraw,
  ...props
}: Props) => {
  const chartRef = useRef(null);
  const triangleRef = useRef<Highcharts.SVGElement | null>(null);
  const userAddresses = useMemo(() => Object.keys(depositsPerAddress), [depositsPerAddress]);
  const { YoloPointsExplanationModal } = useYoloConfig();
  const { address: connectedUserAddress } = useAccount();
  const isUserParticipant = userAddresses.some((address) => isAddressEqual(address, connectedUserAddress));
  const network = getNetworkFromYoloContractName(round.contract);
  const { data: participant } = useCurrentParticipant(network, { enabled: isUserParticipant });

  const { highlightedUser, setHighlightedUser } = useYoloStore();

  const willRoundFail =
    !!round.cutoffTime && round.cutoffTime <= getUnixTime(Date.now()) && round.numberOfParticipants <= 1;

  const { colorMode } = useColorMode();
  const isDarkMode = colorMode === "dark";

  delete defaultConfettiOptions.origin;
  const throwConfetti = useThrowConfettiFromElement(chartRef, defaultConfettiOptions);
  const [highchartsChart, setHighchartsChart] = useState<HighchartsChart | null>(null);

  useEffect(() => {
    // Highlight user in chart when highlightedUser is set
    if (highchartsChart) {
      if (highlightedUser) {
        highchartsChart?.series[1].data.forEach((point: PointType) => {
          const castPoint = point as Highcharts.Point;
          if (isAddressEqual(point.userAddress, highlightedUser)) {
            castPoint.select(true);
          } else {
            castPoint.update({ color: point.color }, false); // this somehow removes the opacity highcharts sets on hover
          }
        });
      } else {
        highchartsChart?.series[1].data.forEach((point) => {
          point.select(false);
        });
      }
    }
  }, [highchartsChart, highlightedUser]);

  const roundId = Number(round.onChainId);

  const playerProfileDisclosure = useDisclosure();
  const yoloPointsExplanationDisclosure = useDisclosure();
  const [selectedUserAddress, setSelectedUserAddress] = useState<Address | null>(null);

  const onPieChartPointClick: PointClickCallbackFunction = useCallback(
    (event: PointClickEventObject) => {
      const options = event.point.options as { userAddress: Address };
      setSelectedUserAddress(options.userAddress);
      playerProfileDisclosure.onOpen();
    },
    [playerProfileDisclosure]
  );

  const hasHighlight = highlightedUser !== undefined;
  const shouldRenderArrow =
    !hasHighlight && (round.status === RoundStatus.Drawing || round.status === RoundStatus.Drawn);

  const rawTimerPercentage = useCountdownPercentage(round.cutoffTime || 0, round.roundDuration);
  const timerPercentage = shouldRenderArrow ? 100 : rawTimerPercentage;

  const duration = useCurrentRoundStore((state) => state.duration);
  const timerColor = useTimerColor(timerPercentage, duration);

  const onPieChartPointMouseOver: PointMouseOverCallbackFunction = useCallback(
    (point) => {
      const { userAddress } = point.target as PointType;

      setHighlightedUser(userAddress);
    },
    [setHighlightedUser]
  );

  const onPieChartPointMouseOut: PointMouseOutCallbackFunction = useCallback(() => {
    setHighlightedUser(undefined);
  }, [setHighlightedUser]);

  const finishRoundCallback = useCallback(() => {
    const resetChartOpacity = () =>
      highchartsChart?.series[1].data.forEach(function (point) {
        if (!point.color) {
          return;
        }
        const shouldRedraw = point.index === highchartsChart.series[1].data.length - 1; // only trigger once every point has been set
        point.update({ color: Highcharts.color(point.color).setOpacity(1).get() }, shouldRedraw);
      });

    resetChartOpacity();
    setIsWinnerHighlighted(false);
  }, [highchartsChart?.series, setIsWinnerHighlighted]);

  const renderArrow = useCallback(
    (chart: HighchartsChart | null) => {
      try {
        triangleRef.current?.destroy();
      } catch {}

      if (!chart || !shouldRenderArrow) {
        return;
      }

      triangleRef.current = chart.renderer
        .path([
          ["M", chart.chartWidth / 2 - 13, chart.plotTop - 12],
          ["L", chart.chartWidth / 2 + 13, chart.plotTop - 12],
          ["L", chart.chartWidth / 2, chart.plotTop + 15],
          ["Z"],
        ])
        .attr({
          fill: isDarkMode ? palette.white[900] : palette.black[900],
          zIndex: 10,
        })
        .add()
        .translate(0, 25);
    },
    [isDarkMode, shouldRenderArrow]
  );

  const tooltipFormatter: HighchartsTooltipFormatterFunction = ({ point }) => {
    if (!point) {
      return null;
    }

    const { deposits, userAddress } = point as PointType;

    if (!deposits || !userAddress) {
      return null;
    }

    return (
      <PlayerTile key={userAddress} userAddress={userAddress} rightColor={userColors[userAddress]} round={round} />
    );
  };

  const [options] = useState<Highcharts.Options>({
    chart: {
      styledMode: false,
      reflow: true,
      height: "100%",
      margin: [0, 0, 0, 0],
    },
    plotOptions: {
      pie: {
        borderWidth: 0,
        borderColor: "#000000",
        enableMouseTracking: !!userAddresses?.length,
      },
    },
    series: [
      {
        id: "Timer",
        type: "pie",
        innerSize: "98%",
        size: "100%",
        enableMouseTracking: false,
        dataLabels: {
          enabled: false,
        },
        data: [
          {
            name: "Elapsed",
            y: timerPercentage,
            className: "highcharts-timer",
          },
          {
            name: "Remaining",
            y: 100 - timerPercentage,
            className: "highcharts-timer",
          },
        ],
      },
      {
        id: "Yolo",
        name: "Entries",
        type: "pie",
        innerSize: "70%",
        size: "95%",
        borderWidth: 1,
        enableMouseTracking: true,
        dataLabels: {
          enabled: false,
        },
        // this doesn't actually place the colors, but it makes sure the correct color classes are set.
        // Without this, highcharts repeats after 10 colors
        colors: userAddresses.map((userAddress) => userColors[userAddress]),
        data: userAddresses?.length
          ? userAddresses.map((userAddress) => {
              return {
                name: formatAddress(userAddress),
                userAddress,
                deposits: depositsPerAddress[userAddress],
                y: depositsPerAddress[userAddress].reduce((acc, deposit) => acc + Number(deposit.numberOfEntries), 0),
              };
            })
          : [
              {
                name: "Yolo",
                y: 100,
              },
            ],
        point: {
          events: {
            click: onPieChartPointClick,
            mouseOver: onPieChartPointMouseOver,
            mouseOut: onPieChartPointMouseOut,
          },
        },
        startAngle: 360,
      },
    ],
  });

  useEffect(() => {
    if (!isHistoricRound) {
      highchartsChart?.update(
        {
          series: [
            {
              id: "Timer",
              type: "pie",
              data: [
                {
                  name: "Elapsed",
                  y: timerPercentage,
                  className: "highcharts-timer",
                },
                {
                  name: "Remaining",
                  y: 100 - timerPercentage,
                  className: "highcharts-timer",
                },
              ],
            },
          ],
        },
        true,
        false,
        true
      );
    }

    if (hasHighlight && highchartsChart?.hoverPoint) {
      highchartsChart.tooltip.refresh(highchartsChart.hoverPoint);
    }
  }, [timerPercentage, roundId, isHistoricRound, hasHighlight, highchartsChart]);

  useEffect(() => {
    highchartsChart?.update(
      {
        series: [
          {
            id: "Yolo",
            type: "pie",
            // this doesn't actually place the colors, but it makes sure the correct color classes are set.
            // Without this, highcharts repeats after 10 colors
            colors: userAddresses.map((userAddress) => userColors[userAddress]),
            data: userAddresses?.length
              ? userAddresses.map((userAddress) => ({
                  name: formatAddress(userAddress),
                  userAddress,
                  deposits: depositsPerAddress[userAddress],
                  y: depositsPerAddress[userAddress].reduce((acc, deposit) => acc + Number(deposit.numberOfEntries), 0), // @TODO clamp within MAX INT
                }))
              : [
                  {
                    name: "Yolo",
                    y: 100,
                    color: colors.gray[700],
                  },
                ],
            point: {
              events: {
                click: onPieChartPointClick,
                mouseOver: onPieChartPointMouseOver,
                mouseOut: onPieChartPointMouseOut,
              },
            },
          },
        ],
      },
      true,
      false,
      true
    );
    // Shortcut the useEffect to only run when the chart data changes, re-drawing is expensive
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [depositsPerAddress, userAddresses]);

  useEffect(() => {
    if (chartRef.current) {
      Highcharts.chart(
        chartRef.current,
        Highcharts.merge(defaultHighchartsOptions, options),
        (chart: HighchartsChart) => {
          setHighchartsChart(chart);
        }
      );
    }
  }, [chartRef, options]);

  const handleSpinEnd = useCallback(() => {
    throwConfetti();
    if (isAddressEqual(round.winner?.address, connectedUserAddress)) {
      startClaim();
    } else {
      finishRound && finishRound(finishRoundCallback);
    }
    setIsWinnerHighlighted(true);
  }, [
    throwConfetti,
    round.winner?.address,
    connectedUserAddress,
    setIsWinnerHighlighted,
    startClaim,
    finishRound,
    finishRoundCallback,
  ]);

  const usersDepositsKeys = keys(depositsPerAddress);
  const winnerIndex = findIndex(usersDepositsKeys, (address) => isAddressEqual(round.winner?.address, address));

  const { startSpinning, isSpinning, reset } = useRouletteSpin(highchartsChart, winnerIndex, handleSpinEnd);

  useEffect(() => {
    if (!isHistoricRound && (round.status === RoundStatus.Drawing || round.status === RoundStatus.Drawn)) {
      startSpinning();
    }
  }, [round.status, startSpinning, isHistoricRound]);

  const prevRoundId = usePreviousValue(roundId);

  useEffect(() => {
    if (roundId !== prevRoundId) {
      reset();
    }
  }, [prevRoundId, reset, roundId]);

  useEffect(() => {
    if (round.status === RoundStatus.Cancelled) {
      finishRound && finishRound(finishRoundCallback);
    }
  }, [finishRound, round.status, finishRoundCallback]);

  useEffect(() => {
    renderArrow(highchartsChart);
  }, [highchartsChart, renderArrow]);

  const isGeneratingRandomness =
    round.status === RoundStatus.Open && !!round.cutoffTime && round.cutoffTime <= getUnixTime(Date.now());
  const isDrawingWinner = isGeneratingRandomness || round.status === RoundStatus.Drawing || isSpinning;

  const renderCenter = () => {
    if (isSpinning && ![RoundStatus.Drawing, RoundStatus.Drawn].includes(round.status)) {
      return null;
    }

    if (willRoundFail) {
      const hasUserDeposits = !!participant?.yoloUnclaimedRefunds.find((refund) => refund.round.onChainId === roundId);
      return (
        <CenterFailed
          timeBeforeNextRound={timeBeforeNextRound}
          roundStatus={round.status}
          onWithdrawRolloverClick={hasUserDeposits ? startWithdraw : undefined}
        />
      );
    }

    if (round.winner && !isSpinning) {
      return (
        <CenterWinner
          claimed={round.deposits.every((deposit) => deposit.claimed)}
          round={round}
          timeLeft={timeBeforeNextRound}
          onClaimRolloverClick={startClaim}
          onClickUser={(address: Address) => {
            setSelectedUserAddress(address);
            playerProfileDisclosure.onOpen();
          }}
        />
      );
    }

    return <CenterActive round={round} />;
  };

  return (
    <>
      <YoloPointsExplanationModal
        isOpen={yoloPointsExplanationDisclosure.isOpen}
        onClose={yoloPointsExplanationDisclosure.onClose}
        network={network}
      />

      {/* CSS trick to keep the ratio 1:1 and avoid layout shift when the chart is loading */}
      <Flex flexDirection="column" position="relative" width="100%" paddingTop="100%" {...props}>
        <Box position="absolute" top={0} bottom={0} left={0} right={0} ref={chartRef} />
        <Flex
          position="absolute"
          top="48%"
          left="50%"
          transform="translate(-50%, -50%)"
          justifyContent="center"
          alignItems="center"
          width="60%" // Stay within the donut chart
          gap={3}
        >
          {renderCenter()}
        </Flex>
        {selectedUserAddress && (
          <PlayerProfileModal
            isOpen={playerProfileDisclosure.isOpen}
            onClose={() => {
              playerProfileDisclosure.onClose();
              setSelectedUserAddress(null);
            }}
            onGemsHelpClick={yoloPointsExplanationDisclosure.onOpen}
            userAddress={selectedUserAddress}
            round={round}
          />
        )}
        {highchartsChart && (
          <HighchartsTooltip chart={highchartsChart} tooltipFormatter={tooltipFormatter} withContainer={false} />
        )}
      </Flex>
      <style jsx global>
        {`
          .highcharts-pie-series .highcharts-point {
            stroke-linejoin: round !important;
            stroke: none !important;
            cursor: pointer;
          }

          ${userAddresses
            .map(
              (address, i) => `
                .highcharts-color-${i}:not(.highcharts-timer) {
                  fill: ${userColors[address]};
                  stroke: ${isDarkMode ? "#0E1113" : "#ffffff"} !important;
                  stroke-width: 0;
                  fill-opacity: ${isHistoricRound && !isAddressEqual(address, round.winner?.address) ? 0.25 : 1};
                }`
            )
            .join("")}

          ${userAddresses.length
            ? ""
            : `.highcharts-color-0:not(.highcharts-timer) {
                fill: ${get(palette, get(semanticTokens.colors["ui-02"], isDarkMode ? "_dark" : "_light"))};
              }`}

          .highcharts-color-0.highcharts-timer {
            fill: ${hasHighlight
              ? "transparent"
              : timerPercentage >= 100
              ? timerColor
              : isDarkMode
              ? palette.gray[700]
              : palette.gray[100]};
          }

          .highcharts-color-1.highcharts-timer {
            fill: ${hasHighlight ? "transparent" : timerColor};
          }

          ${isGeneratingRandomness
            ? `.highcharts-series-0 .highcharts-point {
                fill-opacity: 0.5;
              }`
            : ""}

          ${hasHighlight || isDrawingWinner
            ? `.highcharts-series-1 .highcharts-point:not(.highcharts-point-select) {
                fill-opacity: 0.5;
              }`
            : ""}
        `}
      </style>
    </>
  );
};
