import Highcharts, { Chart as HighchartsChart } from "highcharts";
import { useCallback, useEffect, useRef, useState } from "react";

const physics = {
  deceleration: 0.993, // lower = faster. Good value was found empirically, floating point is also funky
  decelerationThreshold: 5,
  stopThreshold: 0.3,
  speed: 6, // lower = faster
  minSpeed: 0.07, // Avoid getting stuck
};

const getAngleDifference = (angle1: number, angle2: number) => {
  const diff = ((angle2 - angle1 + 180) % 360) - 180;
  return diff < -180 ? diff + 360 : diff;
};

const calculateWinnerAngle = (highchartsChart: HighchartsChart | null, winnerIndex: number | undefined) => {
  if (!highchartsChart || winnerIndex === undefined || winnerIndex < 0) {
    return null;
  }

  if (highchartsChart.series[1].data.length - 1 < winnerIndex) {
    return null;
  }

  const numSlices = highchartsChart.series[1].data.length;
  let total = 0,
    winnerShareStart = 0;

  for (let i = 0; i < numSlices; i++) {
    const share = highchartsChart.series[1].data[i].y || 0;
    if (i < winnerIndex) {
      winnerShareStart += share;
    }
    total += share;
  }

  const winnerMiddle = (winnerShareStart * 2 + (highchartsChart.series[1].data[winnerIndex].y || 0)) / 2;
  const winnerAngle = 360 - 360 * (winnerMiddle / total);

  return winnerAngle;
};

const checkShouldDecelerate = (
  currentAngle: number,
  winnerAngle: number | null,
  rotatedWhileDecelerating: number,
  spins: number,
  minAmountOfSpins: number
) => {
  if (winnerAngle === null) {
    return false;
  }

  if (spins < minAmountOfSpins) {
    return false;
  }
  const finalDestination = (currentAngle + rotatedWhileDecelerating) % 360;
  const distance = getAngleDifference(winnerAngle, finalDestination);
  const result = distance >= 0 && distance < physics.decelerationThreshold;

  return result;
};

const updateChartAngle = (chart?: HighchartsChart, currentAngle = 0) => {
  chart?.update(
    {
      series: [
        {
          id: "Yolo",
          startAngle: currentAngle,
          type: "pie",
        },
      ],
    },
    true,
    false,
    false
  );
};

const setChartWinningStyle = (chart: HighchartsChart, winnerIndex: number) => {
  chart.series[1].data.forEach(function (point, index) {
    if (!point.color) {
      return;
    }
    const isWinner = index === winnerIndex;
    const shouldRedraw = index === chart.series[1].data.length - 1; // only trigger once every point has been set
    point.update(
      {
        color: Highcharts.color(point.color)
          .setOpacity(isWinner ? 1 : 0.2)
          .get(),
      },
      shouldRedraw
    );
  });
};

const getInitialState = (): {
  timeout: NodeJS.Timeout | undefined;
  prevIterationTime: number;
  spins: number;
  currentAngle: number;
  speed: number;
  rotatedWhileDecelerating: number;
  hasTriggeredOnEnd: boolean;
} => ({
  timeout: undefined,
  prevIterationTime: Date.now(),
  spins: 0,
  currentAngle: 360,
  speed: 5,
  rotatedWhileDecelerating: 0,
  hasTriggeredOnEnd: false,
});

export const useRouletteSpin = (
  highchartsChart: HighchartsChart | null,
  winnerIndex: number | undefined,
  onSpinEnd?: () => void,
  minAmountOfSpins = 5
) => {
  const [isSpinning, setIsSpinning] = useState(false);

  // Using a ref instead of state to avoid unnecessary re-renders, as highcharts is updated imperatively outside of react
  const stateRef = useRef(getInitialState());

  const updateCurrentAngle = useCallback(
    (newValue: number) => {
      stateRef.current.currentAngle = newValue;
      !!highchartsChart && updateChartAngle(highchartsChart, newValue);
    },
    [highchartsChart]
  );

  const winnerAngle = calculateWinnerAngle(highchartsChart, winnerIndex);

  const checkShouldEnd = useCallback(() => {
    if (
      Math.abs(stateRef.current.rotatedWhileDecelerating) > 700 && // Ensure it rotates at least almost 2 times
      !!highchartsChart &&
      winnerIndex !== undefined &&
      winnerAngle !== null
    ) {
      const distance = Math.abs(getAngleDifference(winnerAngle, stateRef.current.currentAngle));
      if (distance <= physics.stopThreshold) {
        clearTimeout(stateRef.current.timeout);
        setIsSpinning(false);
        setChartWinningStyle(highchartsChart, winnerIndex);

        return true;
      }
    }
  }, [highchartsChart, winnerAngle, winnerIndex]);

  /** Handles all calculations for the spin animations, it is meant to be called again once the roulette is ready to
   * stop spinning
   * */
  const spin = useCallback(() => {
    clearTimeout(stateRef.current.timeout);

    let timeoutLength = physics.speed - (Date.now() - stateRef.current.prevIterationTime);

    if (timeoutLength < 0) {
      timeoutLength = 0;
    }

    stateRef.current.timeout = setTimeout(() => {
      if (checkShouldEnd()) {
        if (!stateRef.current.hasTriggeredOnEnd) {
          stateRef.current.hasTriggeredOnEnd = true;
          onSpinEnd?.();
        }
        return;
      }

      const shouldDecelerate = checkShouldDecelerate(
        stateRef.current.currentAngle,
        winnerAngle,
        stateRef.current.rotatedWhileDecelerating,
        stateRef.current.spins,
        minAmountOfSpins
      );

      let newAngle = stateRef.current.currentAngle + stateRef.current.speed;
      if (newAngle > 360) {
        newAngle -= 360;
        stateRef.current.spins += 1;
      }
      updateCurrentAngle(newAngle);

      if (shouldDecelerate) {
        stateRef.current.rotatedWhileDecelerating = stateRef.current.rotatedWhileDecelerating - stateRef.current.speed;
        stateRef.current.speed = Math.max(stateRef.current.speed * physics.deceleration, physics.minSpeed);
      }

      stateRef.current.prevIterationTime = Date.now();
      spin();
    }, timeoutLength);
  }, [winnerAngle, minAmountOfSpins, checkShouldEnd, updateCurrentAngle, onSpinEnd]);

  // If not spinning but there is a winner selected
  // We wanna select the winner position (for historic rounds)
  useEffect(() => {
    if (!isSpinning && winnerAngle !== null) {
      updateCurrentAngle(winnerAngle);
    }
  }, [isSpinning, updateCurrentAngle, winnerAngle]);

  const startSpinning = useCallback(() => {
    setIsSpinning(true);
    spin();
  }, [spin]);

  const reset = useCallback(() => {
    clearTimeout(stateRef.current.timeout);
    setIsSpinning(false);
    stateRef.current = getInitialState();
  }, []);

  return { startSpinning, isSpinning, reset };
};
